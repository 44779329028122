<template>
	<div>
		<div v-if="!!serverBusy" id="play-loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<div id="document-container">
			<h2 v-if="documentList.length > 0">Documents Registered to System</h2>
			<div id="paging">
				<button id="addDocuments" title="Add Documents" class="btn" @click="currentModal = 'AddDocumentModal'">Add Documents</button>
				<label title="Set Limit" for="limitOptions">Limit List</label>
				<select v-model="limit" id="limitOptions">
					<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.text }}</option>
				</select>
				<button :disabled="serverBusy" class="prev-button" type="button" title="Previous" @click="previousPage()">Previous</button>
				<button :disabled="serverBusy" class="next-button" type="button" title="Next" @click="nextPage()">Next</button>
				<span :currentPage="currentPage" v-if="!isLastPage">page {{ currentPage }}</span>
				<span :currentPage="currentPage" v-else>Last page</span>
			</div>

			<table id="document-list" v-if="documentList.length > 0">
				<tr class="sticky">
					<th>Key</th>
					<th>Version</th>
					<th>Language</th>
					<th>Format</th>
					<th>URL</th>
				</tr>
				<tr class="doc-row" v-for="(item, index) in documentList" :key="index" :value="item.id">
					<td>{{ item.key }}</td>
					<td>{{ item.version }}</td>
					<td>{{ item.language }}</td>
					<td>{{ item.format }}</td>
					<td class="space-between url">
						<span>{{ item.url }}</span>
						<button class="btn remove" @click="showRemoveDialog(item)" title="Remove this document reference from server">
							Remove
						</button>
					</td>
				</tr>
			</table>
			<h1 v-else>No Documents Found</h1>
			<transition name="fade">
				<component v-bind:is="currentModal" :adminState="adminState" />
			</transition>
		</div>
		<dialog id="remove-dialog">
			<div>
				<p id="remove-text">
					{{`Are you sure you want to remove ${this.currentDocument?.key}; version: ${this.currentDocument?.version}?` }}
				</p>
				<div id="btn-container">
					<button id="confirmButton" class="btn" value="default" @click="removeDocumentReference(this.currentDocument)">Ok</button>
					<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeModal()">Cancel</button>
				</div>
			</div>
		</dialog>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import AddDocumentModal from "@/components/AddDocumentModal.vue";

export default {
	name: "DocumentManager",
	components: {
		AddDocumentModal,
	},
	props: {
		adminState: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			documentList: [],
			currentPage: 1,
			offset: 0,
			limit: this.limit,
			isLastPage: false,
			currentModal: null,
			inputDialog: null,
			currentDocument: null,
		};
	},
	watch: {
		limit() {
			this.currentPage = 1;
			this.offset = 0;
			this.limit = Number(this.limit);
			this.getDocumentList();
		},
	},
	created() {
		this.eventBus.on("closeModal", () => {
			this.closeModal();
		});
		this.eventBus.on("documentReferenceAdded", () => {
			this.getDocumentList();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("closeModal");
			this.eventBus.off("documentReferenceAdded");
		});
	},
	mounted() {
		this.getDocumentList();
		this.inputDialog = document.getElementById("remove-dialog");
	},
	methods: {
		closeModal() {
			this.currentModal = null;
		},
		async getDocumentList() {
			this.busyText = "Loading document list";
			this.serverBusy = true;

			// get one more item than requested to see if a second page exists
			let currentLimit = this.limit + 1;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let requestUrl = new URL("/api/v1/document", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");

			let params = requestUrl.searchParams;

			if (this.offset) params.set("offset", this.offset);
			if (currentLimit) params.set("limit", currentLimit);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				this.isLastPage = dataJson?.length <= this.limit;
				// remove extra item if a next page exists
				if (!this.isLastPage) dataJson.pop();

				this.documentList = dataJson;

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
		async removeDocumentReference(document) {
			this.busyText = "Removing document reference from server";
			this.serverBusy = true;
			this.status.ok = true;

			if (this.status.ok === false) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				documentId: document.id,
			};

			let requestUrl = new URL("/api/v1/document/delete", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				if (fetchStatus?.ok) this.getDocumentList();

				if (fetchStatus?.ok) this.status.message = `Document reference removed successfully`;
				this.status.code = fetchStatus?.code;
				this.status.ok = fetchStatus?.ok;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
		previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.offset = this.offset - this.limit;
			this.getDocumentList();
		},
		nextPage() {
			if (this.isLastPage) return;
			this.offset = this.offset + this.limit;
			this.currentPage++;
			this.getDocumentList();
		},

		showRemoveDialog(document) {
			this.currentDocument = document;
			this.inputDialog.showModal();
		},

		closeModal() {
			this.currentDocument = null;
			this.inputDialog.close();

		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#document-list {
	width: calc(100% - 30px);
	margin: 15px auto;
	box-shadow: 4px 4px 6px rgb(0 0 0 / 40%);
}

th,
td {
	padding: 5px 10px;
}

td {
	color: #000;
}

td.url {
	word-break: break-all;
}

#document-list .btn {
	word-break: normal;
}

.doc-row {
	text-align: center;
	background-color: #9b9b9b;
}

.doc-row:nth-child(2n) {
	background-color: #bfbfbf;
}

.remove {
	margin-left: 20px;
}

.space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#addDocuments {
	display: block;
}

.sticky {
	top: -15px;
}

#remove-dialog {
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}
::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}
#btn-container {
	display: flex;
	justify-content: center;
}

#remove-text {
	text-align: center;
}
</style>
